import { Candidate, CustomField, Skill } from "app/types";
import * as React from "react";
import { fetchCandidateCustomFieldValues, makeTTGetRequest } from "../../apiService";

interface CustomFieldValuesInfo {
  [key: string]: { fieldName: string; fieldValueId: string; fieldValueValue: any };
}

export default function useCustomFieldsInfo(candidate: Candidate | undefined) {
  const [customFieldValuesInfo, setCustomFieldValuesInfo] = React.useState<CustomFieldValuesInfo | undefined>(
    undefined
  );

  const handleFetchCustomFieldValues = React.useCallback(async (candidateId: string) => {
    const candidateCustomFieldValuesResponse = await fetchCandidateCustomFieldValues(candidateId);
    const candidateCustomFieldValues = candidateCustomFieldValuesResponse.parsedBody!;

    const customFieldValuesInfo: CustomFieldValuesInfo = {};

    await Promise.all(
      candidateCustomFieldValues.data.map((customFieldValue) =>
        makeTTGetRequest<CustomField>(customFieldValue.relationships["custom-field"].links.related).then(
          (customField) => {
            const fieldName = customField.parsedBody!.data.attributes["api-name"];
            const fieldValueId = customFieldValue.id;
            let fieldValueValue;

            if (fieldName === 'skills') {
              const skills: Skill[] = [];

              customFieldValue.attributes.value.split(',').forEach((label: string) => {
                label = label.trim();
                if (label.length) {
                  skills.push({
                    label: label,
                    value: label
                  })
                }
              });

              fieldValueValue = skills;
            }
            else {
              fieldValueValue = customFieldValue.attributes.value;
            }

            customFieldValuesInfo[fieldName] = {
              fieldName,
              fieldValueId,
              fieldValueValue,
            };
          }
        )
      )
    );

    setCustomFieldValuesInfo(customFieldValuesInfo);
  }, []);

  React.useEffect(() => {
    if (!!candidate) {
      handleFetchCustomFieldValues(candidate.id);
    }
  }, [candidate, handleFetchCustomFieldValues]);

  return customFieldValuesInfo;
}
