
const config = {
  API_PREFIX: "/api/v1",
  TT_API_URL: "https://api.teamtailor.com/v1",
  TT_NATIONALITY_FIELD_ID: "1901",
  TT_RESIDENCE_FIELD_ID: "940",
  TT_SKILLS_FIELD_ID: "1677",
  TT_EXPERIENCE_FIELD_ID: "3113",
  TT_EDUCATION_FIELD_ID: "3111",
  TT_TRAININGS_AND_CERTIFICATES_FIELD_ID: "3112",
  TT_LANGUAGES_FIELD_ID: "1842",
  TT_EXPECTED_SALARY_FIELD_ID: "982",
  TT_AVAILABILITY_TO_START_FIELD_ID: "3115",
  TT_TITLE_FIELD_ID: "3326",
  TT_WORK_LOCATION_PREFERENCE_FIELD_ID: "3327",
  TT_EMPLOYMENT_TYPE_AND_HOURS_FIELD_ID: "3328",
};

export default config;
