import * as React from "react";
import cn from "classnames";

import "./styles.css";

export interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export const Card: React.FC<CardProps> = ({ children, className }) => {
  const classes = cn("Card", className);

  return <div className={classes}>{children}</div>;
};
