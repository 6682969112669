import * as React from "react";
import cn from "classnames";
import Card from "../../../../../components/Card";
import Button from "../../../../../components/Button";
import calendar from "../../../../../assets/calendar.svg";
import user from "../../../../../assets/user.svg";
import book from "../../../../../assets/book.svg";

import "./styles.css";

export interface EducationInfoCardProps {
  title?: string;
  school: string;
  degree: string;
  fieldOfStudy?: string;
  from: Date | string;
  to: Date | string;
  editForm: any;
  className?: string;
}

export const EducationInfoCard: React.FC<EducationInfoCardProps> = ({
  title,
  school,
  degree,
  fieldOfStudy,
  from,
  to,
  editForm,
  className,
}) => {
  const classes = cn("EducationInfoCard", className);
  return (
    <Card className={classes}>
      <div className="WorkInfoCard__title-div">
        <h3 className="WorkInfoCard__title">{title}</h3>
        <Button className="WorkInfoCard__info-button" type="button" variant="outlined" onClick={editForm}>
          ...
        </Button>
      </div>
      <ul className="EducationInfoCard__info-list">
        <li className="EducationInfoCard__info-item">
          <img src={book} alt="book icon" className="EducationInfoCard__info-icon" />
          <p className="EducationInfoCard__info-text">{school}</p>
        </li>

        <li className="EducationInfoCard__info-item">
          <img src={book} alt="book icon" className="EducationInfoCard__info-icon" />
          <p className="EducationInfoCard__info-text">{degree}</p>
        </li>

        {fieldOfStudy && (
          <li className="EducationInfoCard__info-item">
            <img src={user} alt="user icon" className="EducationInfoCard__info-icon" />
            <p className="EducationInfoCard__info-text">{fieldOfStudy}</p>
          </li>
        )}

        <li className="EducationInfoCard__info-item">
          <img src={calendar} alt="calendar icon" className="EducationInfoCard__info-icon" />
          <p className="EducationInfoCard__info-text">
            {from} - {to}
          </p>
        </li>
      </ul>
    </Card>
  );
};
