import { HttpResponse } from "app/types";

export async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request);

  try {
    response.parsedBody = await response.json();
  } catch (error) {}

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response;
}

export async function get<T>(path: string, args: RequestInit = { method: "get" }): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function post<T>(path: string, args: RequestInit): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, { method: "post", ...args }));
}

export async function patch<T>(path: string, args: RequestInit): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, { method: "PATCH", ...args }));
}
