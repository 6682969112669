import * as React from "react";
import { ErrorMessage, Field, FormikErrors, FormikTouched, getIn } from "formik";
import Button from "../../../../../../components/Button";
import Card from "../../../../../../components/Card";
import DatePicker from "../../../../../../components/DatePicker";
import FloatTextInput from "../../../../../../components/FloatTextInput";
import RadioButton from "../../../../../../components/RadioButton";
import Select from "../../../../../../components/Select";
import TextArea from "../../../../../../components/TextArea";
import { ProfessionalExperienceValues, WorkInfo } from "../../../../types";
import { formatDate } from "../../../../utils";

import "./styles.css";

interface WorkInfoSubFormProps {
  workInfo: WorkInfo;
  workInfoIndex: number;
  errors: FormikErrors<ProfessionalExperienceValues>;
  touched: FormikTouched<ProfessionalExperienceValues>;
  onSetShowSubForm: React.Dispatch<React.SetStateAction<boolean>>;
  onRemoveFieldValue: <T>(index: number) => T | undefined;
  onSetFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
}

export const WorkInfoSubForm: React.FC<WorkInfoSubFormProps> = ({
  workInfo,
  workInfoIndex,
  errors,
  touched,
  onSetShowSubForm,
  onRemoveFieldValue,
  onSetFieldValue,
  onBlur,
}) => {
  const [dateError, setDateError] = React.useState('');

  const handleRemoveValue = React.useCallback(() => {
    onRemoveFieldValue(workInfoIndex);
    onSetShowSubForm(false);
  }, [onRemoveFieldValue, onSetShowSubForm, workInfoIndex]);

  const handleAddValue = React.useCallback(() => {
    onSetFieldValue(`workExperience[${workInfoIndex}].completed`, true);
    onSetFieldValue(`workExperience[${workInfoIndex}].edit`, false);
    onSetShowSubForm(false);
  }, [workInfoIndex, onSetShowSubForm, onSetFieldValue]);

  return (
    <Card className="WorkInfoSubForm">
      <Field
        name={`workExperience[${workInfoIndex}].title`}
        label="Title"
        placeholder="Enter a title"
        error={
          getIn(errors, `workExperience[${workInfoIndex}].title`) &&
          getIn(touched, `workExperience[${workInfoIndex}].title`)
        }
        as={FloatTextInput}
      />
      <ErrorMessage name={`workExperience[${workInfoIndex}].title`} render={(msg) => <div className="FormControl__label error-message">Required</div>} />

      <Field
        name={`workExperience[${workInfoIndex}].company`}
        label="Company"
        placeholder="Enter a company"
        className="WorkInfoSubForm__field"
        error={
          getIn(errors, `workExperience[${workInfoIndex}].company`) &&
          getIn(touched, `workExperience[${workInfoIndex}].company`)
        }
        as={FloatTextInput}
      />
      <ErrorMessage name={`workExperience[${workInfoIndex}].company`} render={(msg) => <div className="FormControl__label error-message">Required</div>} />

      <Field
        label="Employment type"
        name={`workExperience[${workInfoIndex}].employmentType`}
        className="WorkInfoSubForm__field"
        as={Select}
      >
        <option value="fullTime">Full time</option>
        <option value="partTime">Part time</option>
        <option value="contract">Contract</option>
        <option value="freelance">Freelance</option>
      </Field>

      <div>
        <div className="WorkInfoSubForm__date-fields">
          <div className="WorkInfoSubForm__date-field">
            <DatePicker
              name={`workExperience[${workInfoIndex}].from`}
              label="From"
              placeholder="from"
              selected={new Date(workInfo.from)}
              onChange={(date) => {
                if (!date) {
                  return;
                }
                else if (date > new Date(workInfo.to)) {
                  setDateError('Wrong date range');
                  return;
                }
                else {
                  setDateError('');
                }
                onSetFieldValue(`workExperience[${workInfoIndex}].from`, formatDate(date as Date));
              }}
              onBlur={onBlur}
            />
          </div>

          <div className="WorkInfoSubForm__date-field">
            <DatePicker
              name={`workExperience[${workInfoIndex}].to`}
              label="To"
              placeholder="to"
              selected={new Date(workInfo.to)}
              onChange={(date) => {
                if (!date) {
                  return;
                }
                else if (date < new Date(workInfo.from)) {
                  setDateError('Wrong date range');
                  return;
                }
                else {
                  setDateError('');
                }

                onSetFieldValue(`workExperience[${workInfoIndex}].to`, formatDate(date as Date));
              }}
              onBlur={onBlur}
            />
          </div>
        </div>
        { dateError && <div className="error-message">{dateError}</div>}
      </div>

      <Field
        name={`workExperience[${workInfoIndex}].description`}
        placeholder="Description..."
        className="WorkInfoSubForm__description"
        error={
          getIn(errors, `workExperience[${workInfoIndex}].description`) &&
          getIn(touched, `workExperience[${workInfoIndex}].description`)
        }
        as={TextArea}
      />
      <ErrorMessage name={`workExperience[${workInfoIndex}].description`} render={(msg) => <div className="FormControl__label error-message">Required</div>} />

      <div className="WorkInfoSubForm__radio-group">
        <Field
          name={`workExperience[${workInfoIndex}].workType`}
          label="Remote"
          value="remote"
          type="radio"
          as={RadioButton}
        />

        <Field
          name={`workExperience[${workInfoIndex}].workType`}
          label="Onsite"
          value="onSite"
          type="radio"
          as={RadioButton}
        />
      </div>

      <div className="WorkInfoSubForm__actions">
        <Button variant="outlined" type="button" onClick={handleRemoveValue}>
          { workInfo.edit ? 'Remove' : 'Cancel' }
        </Button>
        <Button onClick={handleAddValue} type="button" disabled={!!errors.workExperience || dateError.length > 0}>
          { workInfo.edit ? 'Save' : 'Add' }
        </Button>
      </div>
    </Card>
  );
};
