import React from "react";
import logo from "./assets/logo.svg";
import noIdImg from "./assets/redirect.png";
import ProgressBar from "./components/ProgressBar";
import Toast from "./components/Toast";
import JobApplication, { Step, getProgress } from "./forms/JobApplication";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import "./App.css";
import Button from "./components/Button";

function App() {
  const [step, setStep] = React.useState<Step>(Step.PersonalInfo);

  return (
    <Router>
      <Switch>
        <Route path='/thankyou' render={() => (
          <div className="AppNoId">
            <div className="App__logo">
              <img src={logo} alt="yougig logo" />
            </div>
            <div className="AppNoId__painted"></div>
            <div className="AppNoId__topHalf">
              <div><img src={noIdImg} alt="yougig" /></div>
            </div>
            <div className="AppNoId__bottomHalf">
              <div>Thank you for your application.</div>
              <div>
                <a href="https://jobs.yougig.work"><Button>See more job positions</Button></a>
              </div>
            </div>
          </div>
        )} />
        <Route path='/jobs/:jobId' render={(rProps) => (
          <div className="App">
            <section className="App__section">
              <img src={logo} alt="yougig logo" />
            </section>
            <div className="App__content">
              <div className="App__wrapper">
                <div>
                  <ProgressBar progress={getProgress(step)} />
                  <JobApplication className="App__form" step={step} setStep={setStep} jobId={rProps.match.params.jobId} />
                </div>
              </div>
            </div>
            <Toast />
          </div>
        )} />
        <Route path='/' render={() => (
          <div className="AppNoId">
            <div className="App__logo">
              <img src={logo} alt="yougig logo" />
            </div>
            <div className="AppNoId__painted"></div>
            <div className="AppNoId__topHalf">
              <div><img src={noIdImg} alt="yougig" /></div>
            </div>
            <div className="AppNoId__bottomHalf">
              <div>Click and apply to new job positions.</div>
              <div>
                <a href="https://jobs.yougig.work"><Button>I’m interested</Button></a>
              </div>
            </div>
          </div>
        )} />
      </Switch>
    </Router>
  );
}

export default App;
