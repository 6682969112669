import * as React from "react";
import { ErrorMessage, FormikErrors, FormikTouched } from "formik";
import { Country } from "app/types";
import FormControl from "../../../../components/FormControl";
import TextInput from "../../../../components/TextInput";
import TextArea from "../../../../components/TextArea";
import SelectField from "../../../../components/SelectField";
import { PersonalInfoValues } from "../../types";

import "./styles.css";

interface PersonalInfoProps {
  values: PersonalInfoValues;
  errors: FormikErrors<PersonalInfoValues>;
  touched: FormikTouched<PersonalInfoValues>;
  countries: Country[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  handleFetchCandidates: (email: string) => Promise<void>;
}

export const PersonalInfo: React.FC<PersonalInfoProps> = ({
  values,
  errors,
  touched,
  countries,
  handleChange,
  handleBlur,
  handleFetchCandidates,
}) => {
  const { email: emailError } = errors;
  const { email: emailValue, phone: phoneValue } = values;

  const handleEmailBlur = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const tempEmail = emailValue.toLowerCase();
      if (tempEmail !== "" && !emailError) {
        handleFetchCandidates(tempEmail);
      }
      handleBlur(event);
    },
    [emailError, emailValue, handleFetchCandidates, handleBlur]
  );

  const handlePhoneChange = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (!!event.target.value.match(new RegExp("^[0-9 +()]+$")) || event.target.value === "") {
        handleChange(event);
      } else {
        event.target.value = `${phoneValue}`;
        handleChange(event);
      }
    },
    [phoneValue, handleChange]
  );

  return (
    <React.Fragment>
      <FormControl label="What's your email?" error={touched.email && !!errors.email}>
        <TextInput
          placeholder="It will be hard to reach you if you botch this one"
          type="email"
          name="email"
          error={touched.email && !!errors.email}
          value={values.email}
          onChange={handleChange}
          onBlur={handleEmailBlur}
        />
        <ErrorMessage name="email" render={(msg) => <div className="FormControl__label error-message">{msg.includes('required') ? 'Required' : 'Enter valid email'}</div>} />
      </FormControl>

      <FormControl label="What's your name?" error={touched.fullName && !!errors.fullName}>
        <TextInput
          name="fullName"
          placeholder="First and last name will do"
          error={!!touched.fullName && !!errors.fullName}
          value={values.fullName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorMessage name="fullName" render={(msg) => <div className="FormControl__label error-message">{msg.includes('required') ? 'Required' : 'Enter full name with latin characters'}</div>} />
      </FormControl>

      <FormControl label="What's your title?" className="JobApplication__field" error={touched.title && !!errors.title}>
        <TextInput
          placeholder="Keep short and to the point"
          name="title"
          error={touched.title && !!errors.title}
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorMessage name="title" render={(msg) => <div className="FormControl__label error-message">Required</div>} />
      </FormControl>

      <FormControl label="What's your mobile number?" error={touched.phone && !!errors.phone}>
        <TextInput
          placeholder="Just in case we urgently need to contact you"
          name="phone"
          error={touched.phone && !!errors.phone}
          value={values.phone}
          onChange={handlePhoneChange}
          onBlur={handleBlur}
        />
        <div className="FormControl__label field__comment">Enter valid phone number starting with + and including the country code with no use of special characters or spaces. The number of digits should be between x to 15.</div>
        <ErrorMessage name="phone" render={(msg) => <div className="FormControl__label error-message">Required</div>} />
      </FormControl>

      <FormControl label="What’s your nationality?" error={touched.nationality && !!errors.nationality}>
        <SelectField placeholder="Ours is from everywhere" name="nationality" options={countries} />
        <ErrorMessage name="nationality" render={(msg) => <div className="FormControl__label error-message">Required</div>} />
      </FormControl>

      <FormControl label="Where do you currently live?" error={touched.residence && !!errors.residence}>
        <SelectField
          placeholder="We don't want to call you in the middle of the night"
          name="residence"
          options={countries}
        />
        <ErrorMessage name="countries" render={(msg) => <div className="FormControl__label error-message">Required</div>} />
      </FormControl>

      <FormControl label="We'd love to know more about you" error={touched.pitch && !!errors.pitch}>
        <TextArea
          placeholder="Help us understand your personality, just a sentence or two"
          className="PersonalInfo__pitch"
          error={touched.pitch && !!errors.pitch}
          value={values.pitch}
          onChange={handleChange}
          onBlur={handleBlur}
          name="pitch"
        />
        <ErrorMessage name="pitch" render={(msg) => <div className="FormControl__label error-message">Required</div>} />
      </FormControl>
    </React.Fragment>
  );
};
