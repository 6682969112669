import { Candidates, Skill, Country, CandidateResponse, CustomFieldValues, HttpResponse } from "app/types";
import { get, post } from "./http";
import config from "../config";

function fetchTT(method: string, url: string, parameters?: any, headers?: any) {
  return post<any>(`${config.API_PREFIX}/tt`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      method: method,
      url: url,
      parameters: parameters,
      headers: headers
    }),
  })
}

export function fetchSkills(): Promise<HttpResponse<Skill[]>> {
  return get<Skill[]>(`${config.API_PREFIX}/skills`);
}

export function fetchCountries(): Promise<HttpResponse<Country[]>> {
  return get<Country[]>(`${config.API_PREFIX}/countries`);
}

export function fetchCandidates(email: string): Promise<HttpResponse<Candidates>> {
  const url = new URL(`${config.TT_API_URL}/candidates`);
  url.searchParams.append("filter[email]", email.toLowerCase());

  return fetchTT('GET', url.toString());
}

export function fetchJob(id: string): Promise<HttpResponse<any>> {
  // "995119"
  const url = new URL(`${config.TT_API_URL}/jobs/${id}`);

  return fetchTT('GET', url.toString());
}

export function createCandidate({
  email,
  firstName,
  lastName,
  phone,
  pitch,
}: {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  pitch: string;
}): Promise<HttpResponse<CandidateResponse>> {
  const data = {
    data: {
      type: "candidates",
      attributes: {
        "first-name": firstName,
        "last-name": lastName,
        pitch: pitch,
        email: email.toLowerCase(),
        phone: phone,
      },
    },
  };

  return fetchTT('POST', `${config.TT_API_URL}/candidates`, data);
}

export function updateCandidate(
  candidateId: string,
  {
    email,
    firstName,
    lastName,
    phone,
    pitch,
  }: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    pitch: string;
  }
): Promise<HttpResponse<CandidateResponse>> {
  const data = {
    data: {
      type: "candidates",
      id: candidateId,
      attributes: {
        "first-name": firstName,
        "last-name": lastName,
        pitch: pitch,
        email: email.toLowerCase(),
        phone: phone,
      },
    },
  };

  return fetchTT('PATCH', `${config.TT_API_URL}/candidates/${candidateId}`, data);
}

export function createCustomFieldValue(customFieldId: string, candidateId: string, value: any): Promise<HttpResponse<unknown>> {
  const data = {
    data: {
      type: "custom-field-values",
      attributes: {
        value,
      },
      relationships: {
        "custom-field": {
          data: {
            id: customFieldId,
            type: "custom-fields",
          },
        },
        owner: {
          data: {
            id: candidateId,
            type: "candidates",
          },
        },
      },
    },
  };

  return fetchTT('POST', `${config.TT_API_URL}/custom-field-values`, data);
}

export function updateCustomFieldValue(
  customFieldValueId: string,
  customFieldId: string,
  candidateId: string,
  value: any
): Promise<HttpResponse<unknown>> {
  const data = {
    data: {
      id: customFieldValueId,
      type: "custom-field-values",
      attributes: {
        value,
      },
      relationships: {
        "custom-field": {
          data: {
            id: customFieldId,
            type: "custom-fields",
          },
        },
        owner: {
          data: {
            id: candidateId,
            type: "candidates",
          },
        },
      },
    },
  };

  return fetchTT('PATCH', `${config.TT_API_URL}/custom-field-values/${customFieldValueId}`, data);
}

export function makeTTGetRequest<R>(url: string): Promise<HttpResponse<R>> {
  return fetchTT('GET', url);
}

export function createDepartment(name: string): Promise<HttpResponse<unknown>> {
  const data = { data: { type: "departments", attributes: { name } } };

  return fetchTT('POST', `${config.TT_API_URL}/departments`, data);
}

export function fetchCandidateCustomFieldValues(candidateId: string): Promise<HttpResponse<CustomFieldValues>> {
  const url = new URL(`${config.TT_API_URL}/candidates/${candidateId}/custom-field-values`);
  url.searchParams.append("page[size]", "30");
  url.searchParams.append("page[number]", "1");

  return fetchTT('GET', url.toString());
}

export function createJobApplication(candidateId: string, jobId: string): Promise<HttpResponse<unknown>> {
  const data = {
    data: {
      type: "job-applications",
      attributes: {
        "cover-letter": "",
        sourced: false,
        "send-user-notifications": true,
      },
      relationships: {
        candidate: {
          data: {
            id: candidateId,
            type: "candidates",
          },
        },
        job: {
          data: {
            id: jobId,
            type: "jobs",
          },
        },
      },
    },
  };

  return fetchTT('POST', `${config.TT_API_URL}/job-applications`, data);
}
