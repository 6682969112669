import * as React from "react";
import cn from "classnames";
import Card from "../../../../../../components/Card";
import Button from "../../../../../../components/Button";
import briefcase from "../../../../../../assets/briefcase.svg";
import calendar from "../../../../../../assets/calendar.svg";
import user from "../../../../../../assets/user.svg";
import { getEmploymentTypeLabel } from "../../../../utils";
import { EmploymentType } from "../../../../types";
// import mapPin from "../../../../../../assets/map-pin.svg";

import "./styles.css";

export interface WorkInfoCardProps {
  title: string;
  company: string;
  employmentType: EmploymentType;
  from: Date | string;
  to: Date | string;
  editForm: any;
  className?: string;
}

export const WorkInfoCard: React.FC<WorkInfoCardProps> = ({ title, company, employmentType, from, to, editForm, className }) => {
  const classes = cn("WorkInfoCard", className);
  return (
    <Card className={classes}>
      <div className="WorkInfoCard__title-div">
        <h3 className="WorkInfoCard__title">{title}</h3>
        <Button className="WorkInfoCard__info-button" type="button" variant="outlined" onClick={editForm}>
          ...
        </Button>
      </div>
      <ul className="WorkInfoCard__info-list">
        <li className="WorkInfoCard__info-item">
          <img src={briefcase} alt="briefcase icon" className="WorkInfoCard__info-icon" />
          <p className="WorkInfoCard__info-text">{company}</p>
        </li>

        <li className="WorkInfoCard__info-item">
          <img src={user} alt="user icon" className="WorkInfoCard__info-icon" />
          <p className="WorkInfoCard__info-text">{getEmploymentTypeLabel(employmentType)}</p>
        </li>

        <li className="WorkInfoCard__info-item">
          <img src={calendar} alt="calendar icon" className="WorkInfoCard__info-icon" />
          <p className="WorkInfoCard__info-text">
            {from} - {to}
          </p>
        </li>

        { /* <li>
          <img src={mapPin} alt="map pin icon" className="WorkInfoCard__info-icon" />
          <p className="WorkInfoCard__info-text">{company}</p>
        </li> */ }
      </ul>
    </Card>
  );
};
