import * as React from "react";
import { FieldArrayRenderProps } from "formik";
import Button from "../../../../../components/Button";
import { WorkInfo } from "../../../types";
import { formatDate } from "../../../utils";
import WorkInfoCard from "./WorkInfoCard";
import WorkInfoSubForm from "./WorkInfoSubForm";

import "./styles.css";

interface WorkExperienceFieldProps extends FieldArrayRenderProps {
  workExperience: WorkInfo[];
}

function getInitialValues() {
  return {
    title: "",
    company: "",
    employmentType: "fullTime",
    from: formatDate(new Date()),
    to: formatDate(new Date()),
    description: "",
    workType: "remote",
    completed: false,
  };
}

export const WorkExperienceField: React.FC<WorkExperienceFieldProps> = React.memo(
  ({ workExperience, push, remove, form }) => {
    const [showSubForm, setShowSubForm] = React.useState(false);

    const handleShowSubForm = React.useCallback(() => {
      setShowSubForm(true);
      push(getInitialValues());
    }, [push]);

    const handleShowEditSubForm = React.useCallback((workInfo: WorkInfo) => {
      setShowSubForm(true);
      workInfo.edit = true;
    }, []);

    return (
      <React.Fragment>
        {workExperience.map((workInfo, index) => {
          if (showSubForm && (!workInfo.completed || workInfo.edit)) {
            return (
              <WorkInfoSubForm
                key={index}
                workInfo={workInfo}
                workInfoIndex={index}
                errors={form.errors}
                touched={form.touched}
                onSetShowSubForm={setShowSubForm}
                onRemoveFieldValue={remove}
                onSetFieldValue={form.setFieldValue}
                onBlur={form.handleBlur}
              />
            );
          }

          return (
            <WorkInfoCard
              key={index}
              title={workInfo.title}
              company={workInfo.company}
              employmentType={workInfo.employmentType}
              from={workInfo.from}
              to={workInfo.to}
              editForm={() => handleShowEditSubForm(workInfo)}
              className="WorkExperienceField__WorkInfoCard"
            />
          );
        })}
        {!showSubForm && (
          <Button type="button" variant="outlined" onClick={handleShowSubForm}>
            + Add
          </Button>
        )}
      </React.Fragment>
    );
  }
);
