import * as React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import cn from "classnames";
import { toast } from "react-toastify";
import capitalize from "lodash/capitalize";
import { Candidate, Country, Skill } from "app/types";
import Button from "../../components/Button";
import PersonalInfo from "./steps/PersonalInfo";
import ProfessionalExperience from "./steps/ProfessionalExperience";
import Education from "./steps/Education";
import GeneralInformation from "./steps/GeneralInformation";
import { useValidationSchema } from "./useValidationSchema";
import {
  getStepName,
  getStep,
  generateExperienceText,
  generateEducationText,
  generateCertificatesText,
  generateLanguagesText,
  generateEmploymentTypeAndHoursText,
  parseExperienceText,
  parseEducationText,
  parseCertificatesText,
  parseLanguagesText,
  parseEmploymentTypeAndHoursText,
} from "./utils";
import { Step, FormValues, GeneralInformationValues } from "./types";
import {
  fetchCandidates,
  fetchCountries,
  fetchSkills,
  createCandidate,
  updateCandidate,
  createCustomFieldValue,
  updateCustomFieldValue,
  createJobApplication,
  fetchJob,
} from "../../apiService";
import config from "../../config";
import useCustomFieldsInfo from "./useCustomFieldsInfo";

import "./styles.css";
import { useHistory } from "react-router";

export interface JobApplicationProps {
  step: Step;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
  className?: string;
  jobId?: string;
}

const LANGUAGES_INITIAL_VALUES = {
  english: false,
  other: false,
  otherLanguages: "",
};

const INITIAL_VALUES: FormValues = {
  // PersonalInfo values
  fullName: "",
  title: "",
  email: "",
  phone: "",
  nationality: "",
  residence: "",
  pitch: "",

  // ProfessionalExperience values
  skills: [],
  workExperience: [],

  // Education values
  education: [],
  certificates: [],
  english: LANGUAGES_INITIAL_VALUES.english,
  other: LANGUAGES_INITIAL_VALUES.other,
  otherLanguages: LANGUAGES_INITIAL_VALUES.otherLanguages,

  // GeneralInformation values
  hourlyRate: "",
  workType: "remote",
  employmentType: "fullTime",
  employmentTypeHours: "",
  availabilityDate: "",
};

const jobNotFound = 'You are allowed to apply only on specific job position. Visit jobs.yougig.work';

export const JobApplication: React.FC<JobApplicationProps> = ({ className, step, setStep, jobId }) => {
  const [candidate, setCandidate] = React.useState<Candidate | undefined>(undefined);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [skills, setSkills] = React.useState<Skill[]>([]);
  const customFieldValuesInfo = useCustomFieldsInfo(candidate);
  const [jobExists, setJobExists] = React.useState<boolean>(false);
  const [jobTitle, setJobTitle] = React.useState<string | undefined>(undefined);

  const history = useHistory();

  const checkJob = React.useCallback(async () => {
    try {
      if (jobId) {
        const job = await fetchJob(jobId);
        const status = job.parsedBody.data.attributes.status;
        const title = job.parsedBody.data.attributes.title;

        if (status === 'open') {
          setJobExists(true);
          setJobTitle(title);
        }
        else {
          setJobExists(false);
          toast.error('Job is not open for applications!');
        }
      }
      else {
        setJobExists(false);
        toast.error(jobNotFound);
        setTimeout(() => history.push('/'), 5000);
      }
    }
    catch(error) {
      setJobExists(false);
      toast.error(jobNotFound);
      setTimeout(() => history.push('/'), 5000);
    }
  }, [jobId, history]);

  React.useEffect(() => {
    checkJob();
  }, [checkJob]);

  const handleFetchCandidates = React.useCallback(
    async (email: string) => {
      try {
        if (!candidate) {
          if (jobExists) {
            const response = await fetchCandidates(email);
            const candidates = response.parsedBody!.data;

            if (candidates.length > 0) {
              setCandidate(candidates[0]);
              toast.info(`Hi ${candidates[0].attributes["first-name"]}, seems like this is not your first application with us. Feel free to update your details.`);
            }
          }
          else {
            setJobExists(false);
            setCandidate(undefined);
            setStep(Step.PersonalInfo);
            toast.error(jobNotFound);
            setTimeout(() => history.push('/'), 5000);
          }
        }
      } catch (error) {
        console.log(error.message, "error");
        toast.error(jobNotFound);
        setTimeout(() => history.push('/'), 5000);
      }
    },
    [candidate, setStep, jobExists, history]
  );

  const handleFetchSkills = React.useCallback(async () => {
    try {
      const response = await fetchSkills();
      const skills = response.parsedBody!;
      setSkills(skills);
    } catch (error) {
      console.log(error.message, "error");
    }
  }, []);

  const handleFetchCountries = React.useCallback(async () => {
    try {
      const response = await fetchCountries();
      const countries = response.parsedBody!;
      setCountries(countries);
    } catch (error) {
      console.log(error.message, "error");
    }
  }, []);

  const handlePersonalInfoSubmit = React.useCallback(
    (_values: FormValues, helpers: FormikHelpers<FormValues>) => {
      setStep(Step.ProfessionalExperience);
      helpers.setSubmitting(false);
      helpers.setTouched({});
    },
    [setStep]
  );

  const handleProfessionalExperienceSubmit = React.useCallback(
    (_values: FormValues, helpers: FormikHelpers<FormValues>) => {
      setStep(Step.Education);
      helpers.setSubmitting(false);
      helpers.setTouched({});
    },
    [setStep]
  );

  const handleEducationSubmit = React.useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      if (values.english || values.otherLanguages) {
        setStep(Step.GeneralInformation);
        helpers.setSubmitting(false);
        helpers.setTouched({});
      }
      else {
        helpers.setFieldError('english', 'Please select language - Required');
      }
    },
    [setStep]
  );

  const handleGeneralInfoSubmit = React.useCallback(
    async (values: FormValues) => {
      try {
        const [firstName, lastName] = values.fullName.split(" ");

        const data = {
          firstName,
          lastName,
          phone: values.phone,
          email: values.email,
          pitch: values.pitch,
        };

        const languagesText = generateLanguagesText(values.english, values.other, values.otherLanguages);

        const response = !!candidate ? await updateCandidate(candidate.id, data) : await createCandidate(data);
        const newCandidate = response.parsedBody!;
        const newCandidateId = newCandidate.data.id;

        const promises = [];

        if (customFieldValuesInfo?.nationality) {
          promises.push(
            updateCustomFieldValue(
              customFieldValuesInfo.nationality.fieldValueId,
              config.TT_NATIONALITY_FIELD_ID,
              newCandidateId,
              values.nationality
            )
          );
        } else {
          promises.push(createCustomFieldValue(config.TT_NATIONALITY_FIELD_ID, newCandidateId, values.nationality));
        }

        if (customFieldValuesInfo?.country) {
          promises.push(
            updateCustomFieldValue(
              customFieldValuesInfo.country.fieldValueId,
              config.TT_RESIDENCE_FIELD_ID,
              newCandidateId,
              values.residence
            )
          );
        } else {
          promises.push(createCustomFieldValue(config.TT_RESIDENCE_FIELD_ID, newCandidateId, values.residence));
        }

        let tempSkills = '';

        values.skills.forEach((skill) => tempSkills += skill.label + ',');

        if (customFieldValuesInfo?.skills) {
          promises.push(
            updateCustomFieldValue(
              customFieldValuesInfo.skills.fieldValueId,
              config.TT_SKILLS_FIELD_ID,
              newCandidateId,
              tempSkills
            )
          );
        } else {
          promises.push(createCustomFieldValue(config.TT_SKILLS_FIELD_ID, newCandidateId, tempSkills));
        }

        if (values.workExperience.length > 0) {
          if (customFieldValuesInfo?.experience) {
            promises.push(
              updateCustomFieldValue(
                customFieldValuesInfo.experience.fieldValueId,
                config.TT_EXPERIENCE_FIELD_ID,
                newCandidateId,
                generateExperienceText(values.workExperience)
              )
            );
          } else {
            promises.push(
              createCustomFieldValue(
                config.TT_EXPERIENCE_FIELD_ID,
                newCandidateId,
                generateExperienceText(values.workExperience)
              )
            );
          }
        }

        if (values.education.length > 0) {
          if (customFieldValuesInfo?.education) {
            promises.push(
              updateCustomFieldValue(
                customFieldValuesInfo.education.fieldValueId,
                config.TT_EDUCATION_FIELD_ID,
                newCandidateId,
                generateEducationText(values.education)
              )
            );
          } else {
            promises.push(
              createCustomFieldValue(
                config.TT_EDUCATION_FIELD_ID,
                newCandidateId,
                generateEducationText(values.education)
              )
            );
          }
        }

        if (values.certificates.length > 0) {
          if (customFieldValuesInfo && customFieldValuesInfo["trainings-and-certificates"]) {
            promises.push(
              updateCustomFieldValue(
                customFieldValuesInfo["trainings-and-certificates"].fieldValueId,
                config.TT_TRAININGS_AND_CERTIFICATES_FIELD_ID,
                newCandidateId,
                generateCertificatesText(values.certificates)
              )
            );
          } else {
            promises.push(
              createCustomFieldValue(
                config.TT_TRAININGS_AND_CERTIFICATES_FIELD_ID,
                newCandidateId,
                generateCertificatesText(values.certificates)
              )
            );
          }
        }

        if (languagesText) {
          if (customFieldValuesInfo && customFieldValuesInfo["languages"]) {
            promises.push(
              updateCustomFieldValue(
                customFieldValuesInfo["languages"].fieldValueId,
                config.TT_LANGUAGES_FIELD_ID,
                newCandidateId,
                languagesText
              )
            );
          } else {
            promises.push(createCustomFieldValue(config.TT_LANGUAGES_FIELD_ID, newCandidateId, languagesText));
          }
        }

        if (customFieldValuesInfo && customFieldValuesInfo["expected-salary"]) {
          promises.push(
            updateCustomFieldValue(
              customFieldValuesInfo["expected-salary"].fieldValueId,
              config.TT_EXPECTED_SALARY_FIELD_ID,
              newCandidateId,
              values.hourlyRate.toString()
            )
          );
        } else {
          promises.push(
            createCustomFieldValue(config.TT_EXPECTED_SALARY_FIELD_ID, newCandidateId, values.hourlyRate.toString())
          );
        }

        if (customFieldValuesInfo && customFieldValuesInfo["availability-to-start"]) {
          promises.push(
            updateCustomFieldValue(
              customFieldValuesInfo["availability-to-start"].fieldValueId,
              config.TT_AVAILABILITY_TO_START_FIELD_ID,
              newCandidateId,
              values.availabilityDate
            )
          );
        } else {
          promises.push(
            createCustomFieldValue(config.TT_AVAILABILITY_TO_START_FIELD_ID, newCandidateId, values.availabilityDate)
          );
        }

        if (customFieldValuesInfo?.title) {
          promises.push(
            updateCustomFieldValue(
              customFieldValuesInfo.title.fieldValueId,
              config.TT_TITLE_FIELD_ID,
              newCandidateId,
              values.title
            )
          );
        } else {
          promises.push(createCustomFieldValue(config.TT_TITLE_FIELD_ID, newCandidateId, values.title));
        }

        if (customFieldValuesInfo && customFieldValuesInfo["work-location-preference"]) {
          promises.push(
            updateCustomFieldValue(
              customFieldValuesInfo["work-location-preference"].fieldValueId,
              config.TT_WORK_LOCATION_PREFERENCE_FIELD_ID,
              newCandidateId,
              capitalize(values.workType)
            )
          );
        } else {
          promises.push(
            createCustomFieldValue(
              config.TT_WORK_LOCATION_PREFERENCE_FIELD_ID,
              newCandidateId,
              capitalize(values.workType)
            )
          );
        }

        if (customFieldValuesInfo && customFieldValuesInfo["employment-type-and-hours"]) {
          promises.push(
            updateCustomFieldValue(
              customFieldValuesInfo["employment-type-and-hours"].fieldValueId,
              config.TT_EMPLOYMENT_TYPE_AND_HOURS_FIELD_ID,
              newCandidateId,
              generateEmploymentTypeAndHoursText(values.employmentType, values.employmentTypeHours)
            )
          );
        } else {
          promises.push(
            createCustomFieldValue(
              config.TT_EMPLOYMENT_TYPE_AND_HOURS_FIELD_ID,
              newCandidateId,
              generateEmploymentTypeAndHoursText(values.employmentType, values.employmentTypeHours)
            )
          );
        }

        await Promise.all(promises);

        if (jobId && jobExists) {
          createJobApplication(newCandidateId, jobId)
          .then((response) => toast.success("Job application was created successfully"))
          .catch((err) => console.log(err, 'error'));
        } else {
          toast.error(jobNotFound);
          setTimeout(() => history.push('/'), 5000);
        }

        toast.success(!!candidate ? `${candidate.attributes["first-name"]}, you just updated your details!` : "We added you in candidates database!");

        setTimeout(() => {
          history.push('/thankyou'); 
        }, 5000);
      } catch (error) {
        toast.error("Something went wrong. Please, try again");
        console.log(error, "error");
      }
    },
    [candidate, customFieldValuesInfo, jobId, jobExists, history]
  );

  const submitHandlers = React.useMemo(
    () => ({
      [Step.PersonalInfo]: handlePersonalInfoSubmit,
      [Step.ProfessionalExperience]: handleProfessionalExperienceSubmit,
      [Step.Education]: handleEducationSubmit,
      [Step.GeneralInformation]: handleGeneralInfoSubmit,
    }),
    [handlePersonalInfoSubmit, handleProfessionalExperienceSubmit, handleEducationSubmit, handleGeneralInfoSubmit]
  );

  const previousStep = () => {
    if (step) setStep(step - 1);
  }

  React.useEffect(() => {
    handleFetchSkills();
    handleFetchCountries();
  }, [handleFetchSkills, handleFetchCountries]);

  const initialValues = React.useMemo(() => {
    if (!candidate || !customFieldValuesInfo || Object.keys(customFieldValuesInfo).length === 0) {
      return INITIAL_VALUES;
    }

    const languages = customFieldValuesInfo.languages
      ? parseLanguagesText(customFieldValuesInfo.languages.fieldValueValue)
      : LANGUAGES_INITIAL_VALUES;

    const employment = customFieldValuesInfo["employment-type-and-hours"]
      ? parseEmploymentTypeAndHoursText(customFieldValuesInfo["employment-type-and-hours"].fieldValueValue)
      : undefined;

    return {
      ...INITIAL_VALUES,
      // PersonalInfo values
      email: candidate.attributes.email || "",
      phone: candidate.attributes.phone || "",
      fullName: `${candidate.attributes["first-name"]} ${candidate.attributes["last-name"]}`.trim(),
      pitch: candidate.attributes.pitch || "",
      title: customFieldValuesInfo?.title?.fieldValueValue || "",
      nationality: customFieldValuesInfo?.nationality?.fieldValueValue || "",
      residence: customFieldValuesInfo?.country?.fieldValueValue || "",

      // ProfessionalExperience values
      // skills: customFieldValuesInfo?.skills || "",
      skills: customFieldValuesInfo?.skills.fieldValueValue || [],
      workExperience: customFieldValuesInfo.experience
        ? parseExperienceText(customFieldValuesInfo.experience.fieldValueValue)
        : [],

      // Education values
      education: customFieldValuesInfo.education
        ? parseEducationText(customFieldValuesInfo.education.fieldValueValue)
        : [],
      certificates: customFieldValuesInfo["trainings-and-certificates"]
        ? parseCertificatesText(customFieldValuesInfo["trainings-and-certificates"].fieldValueValue)
        : [],
      english: languages.english,
      other: languages.other,
      otherLanguages: languages.otherLanguages,

      // GeneralInformation values
      hourlyRate: customFieldValuesInfo["expected-salary"]
        ? customFieldValuesInfo["expected-salary"].fieldValueValue
        : "",
      workType: customFieldValuesInfo["work-location-preference"]
        ? (customFieldValuesInfo[
            "work-location-preference"
          ].fieldValueValue.toLowerCase() as GeneralInformationValues["workType"])
        : "remote",
      employmentType: employment?.employmentType || "fullTime",
      employmentTypeHours: employment?.employmentTypeHours || "",
      availabilityDate: customFieldValuesInfo["availability-to-start"]
        ? customFieldValuesInfo["availability-to-start"].fieldValueValue
        : "",
    };
  }, [candidate, customFieldValuesInfo]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={useValidationSchema(step)}
      validateOnMount
      enableReinitialize
      onSubmit={submitHandlers[step]}
    >
      {jobExists ? (formik) => (
        <Form className={cn("JobApplication", className)}>
          <h1 className="JobApplication__title">Job Application Form { jobTitle ? '(' + jobTitle + ')' : ''}</h1>
          <p className="JobApplication__description">
            <span className="JobApplication__description--bold">{getStep(step)}:</span> {getStepName(step)}
          </p>
          <div className="JobApplication__fields">
            {step === Step.PersonalInfo && (
              <PersonalInfo
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                countries={countries}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                handleFetchCandidates={handleFetchCandidates}
              />
            )}

            {step === Step.ProfessionalExperience && (
              <ProfessionalExperience
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                skills={skills}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
              />
            )}

            {step === Step.Education && (
              <Education
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            )}

            {step === Step.GeneralInformation && (
              <GeneralInformation
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                setFieldValue={formik.setFieldValue}
                handleBlur={formik.handleBlur}
              />
            )}
          </div>
          <div className="JobApplication__bottom-line" />
          <div className="JobApplication__button-area">
            <div className="JobApplication__button-area-left">
              {step !== Step.PersonalInfo && (
                <Button
                  type="button"
                  className="JobApplication__submit-button"
                  onClick={previousStep}
                >
                  Previous
                </Button>
              )}
            </div>
            <div className="JobApplication__button-area-right">
              <Button
                type="submit"
                className="JobApplication__submit-button"
              >
                {step !== Step.GeneralInformation ? "Next" : "Submit"}
              </Button>
            </div>
          </div>
        </Form>
      ) : null
      }
    </Formik>
  );
};
