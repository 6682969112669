import * as React from "react";
import cn from "classnames";

import "./styles.css";

export interface FormControlProps {
  children: React.ReactNode;
  label: string;
  error?: boolean;
  className?: string;
}

export const FormControl: React.FC<FormControlProps> = ({ label, children, error, className }) => {
  const classes = cn("FormControl", { "FormControl--error": error }, className);

  return (
    <div className={classes}>
      <div className="FormControl__label">{label}</div>
      {children}
    </div>
  );
};
