import { useField } from "formik";
import * as React from "react";
import { ValueType, OptionsType } from "react-select";
import ReactSelect from "../ReactSelect";

interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  name: string;
  options: OptionsType<Option>;
  placeholder: string;
  isMulti?: boolean;
  className?: string;
}

export const SelectField: React.FC<SelectProps> = ({ name, options, placeholder, isMulti = false, className }) => {
  const [, meta, helpers] = useField(name);

  const { setValue, setTouched } = helpers;
  const { value, error, touched } = meta;

  const handleChange = React.useCallback(
    (option: ValueType<Option | Option[]>) => {
      if (!option) {
        return isMulti ? setValue([]) : setValue("");
      }

      const newValue = isMulti ? (option as Option[]).map((item) => item.value) : (option as Option).value;

      setValue(newValue);
    },
    [setValue, isMulti]
  );

  const getValue = React.useCallback(() => {
    if (options) {
      const newValue = isMulti
        ? options.filter((option) => value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === value);

      if (newValue) {
        return newValue;
      } else {
        return isMulti ? [] : ("" as any);
      }
    } else {
      return isMulti ? [] : ("" as any);
    }
  }, [options, isMulti, value]);

  const isMobilePhone = window.matchMedia(`(max-width: 768px)`).matches;

  return (
    <ReactSelect
      options={options}
      name={name}
      isMulti={isMulti}
      error={touched && !!error}
      touched={touched}
      value={getValue()}
      onChange={handleChange}
      onBlur={() => setTouched(true, isMobilePhone ? false : undefined)}
      placeholder={placeholder}
      className={className}
    />
  );
};
