import { Skill } from "app/types";

export enum Step {
  PersonalInfo = 1,
  ProfessionalExperience = 2,
  Education = 3,
  GeneralInformation = 4,
}

export interface FormValues
  extends PersonalInfoValues,
    ProfessionalExperienceValues,
    EducationValues,
    GeneralInformationValues {}

export interface PersonalInfoValues {
  fullName: string;
  title: string;
  email: string;
  phone: string;
  nationality: string;
  residence: string;
  pitch: string;
}

export interface ProfessionalExperienceValues {
  skills: Skill[];
  // skills: string;
  workExperience: WorkInfo[];
}

export interface WorkInfo {
  title: string;
  company: string;
  employmentType: EmploymentType;
  description: string;
  from: string;
  to: string;
  workType: WorkType;
  completed: boolean;
  edit?: boolean;
}

export type WorkType = "remote" | "onSite";

export type EmploymentType = "fullTime" | "partTime" | "contract" | "freelance";

export interface EducationValues {
  education: EducationItem[];
  certificates: Certificate[];
  english: boolean;
  other: boolean;
  otherLanguages: string;
}

export interface EducationItem {
  title: string;
  school: string;
  degree: string;
  fieldOfStudy: string;
  from: string;
  to: string;
  completed: boolean;
  edit?: boolean;
}

export interface Certificate {
  title: string;
  school: string;
  name: string;
  from: string;
  to: string;
  completed: boolean;
  edit?: boolean;
}

export interface GeneralInformationValues {
  hourlyRate: string;
  workType: "remote" | "relocate" | "rotation";
  employmentType: "partTime" | "fullTime";
  employmentTypeHours: string;
  availabilityDate: string;
}
