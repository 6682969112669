import * as React from "react";
import cn from "classnames";

import "./styles.css";

export interface CheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactNode;
  className?: string;
}

export const CheckBox: React.FC<CheckBoxProps> = ({ className, children, ...rest }) => {
  const classes = cn("CheckBox", className);

  return (
    <label className={classes}>
      <input type="checkbox" className="CheckBox__input" {...rest} />
      <span className="CheckBox__box" />
      {children}
    </label>
  );
};
