import * as React from "react";
import { FormikErrors, FormikTouched, Field, getIn, ErrorMessage } from "formik";
import Button from "../../../../../../components/Button";
import Card from "../../../../../../components/Card";
import DatePicker from "../../../../../../components/DatePicker";
import FloatTextInput from "../../../../../../components/FloatTextInput";
import { EducationItem, EducationValues } from "../../../../types";
import { formatDate } from "../../../../utils";

import "./styles.css";

interface Props {
  educationInfo: EducationItem;
  educationInfoIndex: number;
  errors: FormikErrors<EducationValues>;
  touched: FormikTouched<EducationValues>;
  onSetShowSubForm: React.Dispatch<React.SetStateAction<boolean>>;
  onRemoveFieldValue: <T>(index: number) => T | undefined;
  onSetFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
}

export const EducationInfoSubForm: React.FC<Props> = ({
  educationInfo,
  educationInfoIndex,
  errors,
  touched,
  onSetFieldValue,
  onSetShowSubForm,
  onRemoveFieldValue,
  onBlur,
}) => {
  const [dateError, setDateError] = React.useState('');

  const handleAddEducationValue = React.useCallback(() => {
    onSetFieldValue(`education[${educationInfoIndex}].completed`, true);
    onSetFieldValue(`education[${educationInfoIndex}].edit`, false);
    onSetShowSubForm(false);
  }, [onSetFieldValue, onSetShowSubForm, educationInfoIndex]);

  const handleRemoveEducationValue = React.useCallback(() => {
    onRemoveFieldValue(educationInfoIndex);
    onSetShowSubForm(false);
  }, [educationInfoIndex, onRemoveFieldValue, onSetShowSubForm]);

  return (
    <Card>
      <Field
        name={`education[${educationInfoIndex}].title`}
        label="Title"
        placeholder="Enter a Title"
        error={
          getIn(errors, `education[${educationInfoIndex}].title`) &&
          getIn(touched, `education[${educationInfoIndex}].title`)
        }
        as={FloatTextInput}
      />
      <ErrorMessage name={`education[${educationInfoIndex}].title`} render={(msg) => <div className="FormControl__label error-message">Required</div>} />

      <Field
        name={`education[${educationInfoIndex}].school`}
        label="School"
        placeholder="Enter a School"
        error={
          getIn(errors, `education[${educationInfoIndex}].school`) &&
          getIn(touched, `education[${educationInfoIndex}].school`)
        }
        as={FloatTextInput}
      />
      <ErrorMessage name={`education[${educationInfoIndex}].school`} render={(msg) => <div className="FormControl__label error-message">Required</div>} />

      <Field
        name={`education[${educationInfoIndex}].degree`}
        label="Degree"
        placeholder="Enter a degree"
        className="EducationInfoSubForm__field"
        error={
          getIn(errors, `education[${educationInfoIndex}].degree`) &&
          getIn(touched, `education[${educationInfoIndex}].degree`)
        }
        as={FloatTextInput}
      />
      <ErrorMessage name={`education[${educationInfoIndex}].degree`} render={(msg) => <div className="FormControl__label error-message">Required</div>} />

      <Field
        name={`education[${educationInfoIndex}].fieldOfStudy`}
        label="Field of study"
        placeholder="Enter a field of study"
        className="EducationInfoSubForm__field"
        error={
          getIn(errors, `education[${educationInfoIndex}].fieldOfStudy`) &&
          getIn(touched, `education[${educationInfoIndex}].fieldOfStudy`)
        }
        as={FloatTextInput}
      />
      <ErrorMessage name={`education[${educationInfoIndex}].fieldOfStudy`} render={(msg) => <div className="FormControl__label error-message">Required</div>} />

      <div>
        <div className="EducationInfoSubForm__date-fields">
          <div className="EducationInfoSubForm__date-field">
            <DatePicker
              name={`education[${educationInfoIndex}].from`}
              label="From"
              placeholder="from"
              selected={new Date(educationInfo.from)}
              onChange={(date) => {
                if (!date) {
                  return;
                }
                else if (date > new Date(educationInfo.to)) {
                  setDateError('Wrong date range');
                  return;
                }
                else {
                  setDateError('');
                }

                onSetFieldValue(`education[${educationInfoIndex}].from`, formatDate(date as Date));
              }}
              onBlur={onBlur}
            />
          </div>
          <div className="EducationInfoSubForm__date-field">
            <DatePicker
              name={`education[${educationInfoIndex}].to`}
              label="To"
              placeholder="to"
              selected={new Date(educationInfo.to)}
              onChange={(date) => {
                if (!date) {
                  return;
                }
                else if (date < new Date(educationInfo.from)) {
                  setDateError('Wrong date range');
                  return;
                }
                else {
                  setDateError('');
                }

                onSetFieldValue(`education[${educationInfoIndex}].to`, formatDate(date as Date));
              }}
              onBlur={onBlur}
            />
          </div>
        </div>
        { dateError && <div className="error-message">{dateError}</div>}
      </div>

      <div className="EducationInfoSubForm__actions">
        <Button variant="outlined" type="button" onClick={handleRemoveEducationValue}>
          { educationInfo.edit ? 'Remove' : 'Cancel' }
        </Button>
        <Button onClick={handleAddEducationValue} type="button" disabled={!!errors.education || dateError.length > 0}>
        { educationInfo.edit ? 'Save' : 'Add' }
        </Button>
      </div>
    </Card>
  );
};
