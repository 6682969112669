import * as React from "react";
import { FieldArrayRenderProps } from "formik";
import Button from "../../../../../components/Button";
import { Certificate } from "../../../types";
import { formatDate } from "../../../utils";
import EducationInfoCard from "../EducationInfoCard";
import CertificateInfoSubForm from "./CertificateInfoSubForm";

import "./styles.css";

interface CertificatesFieldProps extends FieldArrayRenderProps {
  certificates: Certificate[];
}

function getCertificateInitialValues() {
  return {
    title: "",
    school: "",
    name: "",
    from: formatDate(new Date()),
    to: formatDate(new Date()),
    completed: false,
  };
}

export const CertificatesField: React.FC<CertificatesFieldProps> = ({ certificates, push, form, remove }) => {
  const [showCertificateSubForm, setShowCertificateSubForm] = React.useState(false);

  const handleShowSubForm = React.useCallback(() => {
    push(getCertificateInitialValues());
    setShowCertificateSubForm(true);
  }, [setShowCertificateSubForm, push]);

  const handleShowEditSubForm = React.useCallback((certificate: Certificate) => {
    setShowCertificateSubForm(true);
    certificate.edit = true;
  }, []);

  return (
    <React.Fragment>
      {certificates.map((certificate, index) => {
        if ((!certificate.completed || certificate.edit) && showCertificateSubForm) {
          return (
            <CertificateInfoSubForm
              key={index}
              certificate={certificate}
              certificateIndex={index}
              errors={form.errors}
              touched={form.touched}
              onSetShowSubForm={setShowCertificateSubForm}
              onRemoveFieldValue={remove}
              onSetFieldValue={form.setFieldValue}
              onBlur={form.handleBlur}
            />
          );
        }

        return (
          <EducationInfoCard
            key={index}
            title={certificate.title}
            school={certificate.school}
            degree={certificate.name}
            to={certificate.to}
            from={certificate.from}
            editForm={() => handleShowEditSubForm(certificate)}
            className="CertificatesField__InfoCard"
          />
        );
      })}

      {!showCertificateSubForm && (
        <Button type="button" variant="outlined" onClick={handleShowSubForm}>
          + Add
        </Button>
      )}
    </React.Fragment>
  );
};
