import * as React from "react";
import cn from "classnames";

import "./input.css";

export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: "standard" | "outlined";
  error?: boolean;
  className?: string;
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ variant = "outlined", className, error, ...rest }, ref) => {
    const classes = cn("Input", `Input--${variant}`, { "Input--error": error }, className);

    return <input className={classes} type="text" {...rest} ref={ref} />;
  }
);
