import { format } from "date-fns";
import {
  Step,
  EmploymentType,
  WorkInfo,
  WorkType,
  EducationItem,
  Certificate,
  GeneralInformationValues,
} from "./types";
import { Skill } from "app/types";
// import { title } from "process";

export function getStep(step: Step) {
  switch (step) {
    case Step.PersonalInfo:
      return "Step One";
    case Step.ProfessionalExperience:
      return "Step Two";
    case Step.Education:
      return "Step Three";
    case Step.GeneralInformation:
      return "Step Four";
  }
}

export function getStepName(step: Step) {
  switch (step) {
    case Step.PersonalInfo:
      return "Personal Info";
    case Step.ProfessionalExperience:
      return "Professional Experience";
    case Step.Education:
      return "Education";
    case Step.GeneralInformation:
      return "General Information";
  }
}

export function getProgress(step: Step) {
  switch (step) {
    case Step.PersonalInfo:
      return 0;
    case Step.ProfessionalExperience:
      return 43;
    case Step.Education:
      return 68;
    case Step.GeneralInformation:
      return 99;
  }
}

export function getEmploymentTypeLabel(type: EmploymentType) {
  switch (type) {
    case "fullTime":
      return "Full time";
    case "partTime":
      return "Part time";
    case "contract":
      return "Contract";
    case "freelance":
      return "Freelance";
  }
}

export function formatDate(date: Date) {
  return format(date, "yyyy-MM-dd");
}

export function generateExperienceText(workExperience: WorkInfo[]) {
  return workExperience
    .filter((item) => !!item.completed)
    .map(
      (item) =>
        `${item.title}, ${item.company}, ${item.employmentType}, ${item.from}-${item.to}, ${item.description}, ${item.workType}`
    )
    .join(";");
}

export function generateEducationText(education: EducationItem[]) {
  return education
    .filter((item) => !!item.completed)
    .map((item) => `${item.title}, ${item.school}, ${item.degree}, ${item.fieldOfStudy}, ${item.from}-${item.to}`)
    .join(";");
}

export function generateCertificatesText(certificates: Certificate[]) {
  return certificates
    .filter((item) => !!item.completed)
    .map((item) => `${item.title}, ${item.school}, ${item.name}, ${item.from}-${item.to}`)
    .join(";");
}

export function generateLanguagesText(english: boolean, other: boolean, otherLanguages: string): string | undefined {
  if (english && !other) {
    return "English";
  }

  if (english && other && otherLanguages !== "") {
    return `English, ${otherLanguages}`;
  }

  return undefined;
}

export function generateEmploymentTypeAndHoursText(
  employmentType: GeneralInformationValues["employmentType"],
  employmentTypeHours: string
) {
  if (employmentType === "fullTime") {
    return "Fulltime: 40";
  }

  return `Parttime: ${employmentTypeHours}`;
}

export function parseExperienceText(experienceText: string): WorkInfo[] {
  const experience = experienceText.split(";");

  return experience.map((item) => {
    const [title, company, employmentType, date, description, workType] = (item || '').split(",");

    const [fromYear, fromMonth, fromDay, toYear, toMonth, toDay] = (date || '').split("-");

    return {
      title: title && title.trim(),
      company: company && company.trim(),
      employmentType: employmentType ? (employmentType.trim() as EmploymentType) : "fullTime",
      description: description && description.trim(),
      from: `${fromYear && fromYear.trim()}-${fromMonth && fromMonth.trim()}-${fromDay && fromDay.trim()}`,
      to: `${toYear && toYear.trim()}-${toMonth && toMonth.trim()}-${toDay && toDay.trim()}`,
      workType: workType ? (workType.trim() as WorkType) : "remote",
      completed: true,
    };
  });
}

export function parseEducationText(educationText: string): EducationItem[] {
  const education = educationText.split(";");

  return education.map((item) => {
    const [title, school, degree, fieldOfStudy, date] = (item || '').split(",").map((itm) => itm.trim());

    const [fromYear, fromMonth, fromDay, toYear, toMonth, toDay] = (date || '').split("-");

    return {
      title: title && title.trim(),
      school: school && school.trim(),
      degree: degree && degree.trim(),
      fieldOfStudy: fieldOfStudy && fieldOfStudy.trim(),
      from: `${fromYear && fromYear.trim()}-${fromMonth && fromMonth.trim()}-${fromDay && fromDay.trim()}`,
      to: `${toYear && toYear.trim()}-${toMonth && toMonth.trim()}-${toDay && toDay.trim()}`,
      completed: true,
    };
  });
}

export function parseCertificatesText(certificatesText: string): Certificate[] {
  const certificates = certificatesText.split(";");

  return certificates.map((item) => {
    const [title, school, name, date] = (item || '').split(",");

    const [fromYear, fromMonth, fromDay, toYear, toMonth, toDay] = (date || '').split("-");

    return {
      title: title && title.trim(),
      school: school && school.trim(),
      name: name && name.trim(),
      from: `${fromYear && fromYear.trim()}-${fromMonth && fromMonth.trim()}-${fromDay && fromDay.trim()}`,
      to: `${toYear && toYear.trim()}-${toMonth && toMonth.trim()}-${toDay && toDay.trim()}`,
      completed: true,
    };
  });
}

export function parseLanguagesText(languagesText: string) {
  const [english, ...otherLanguages] = languagesText.split(",");

  if (english && otherLanguages.length > 0) {
    return {
      english: true,
      other: true,
      otherLanguages: otherLanguages.join(", ").trim(),
    };
  }

  return {
    english: true,
    other: false,
    otherLanguages: "",
  };
}

export function parseEmploymentTypeAndHoursText(employmentTypeAndHoursText: string) {
  const [employmentType, employmentTypeHours] = employmentTypeAndHoursText.split(":");

  return {
    employmentType:
      employmentType.trim() === "Parttime" ? "partTime" : ("fullTime" as GeneralInformationValues["employmentType"]),
    employmentTypeHours: employmentTypeHours && employmentTypeHours.trim(),
  };
}

export function filterSkills(skills: Skill[], search: string, prevOptions: any) {
  let filteredOptions: Skill[] = [];

  try {
    if (!search) {
      filteredOptions = skills;
    } else if (skills.length) {
      const searchLower = search.toLowerCase();
      filteredOptions = skills.filter((skill: Skill) => skill && skill.label && skill.label.toLowerCase().includes(searchLower));
    }
  }
  catch (error) {
    console.log(error, 'error');
  }

  const hasMore = filteredOptions.length > prevOptions.length + 100;
  const slicedOptions = filteredOptions.slice(
    prevOptions.length,
    prevOptions.length + 100
  );

  return {
    options: slicedOptions,
    hasMore
  };
};
