import * as React from "react";
import cn from "classnames";
import TextInput from "../TextInput";

import "./styles.css";

export interface FloatTextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  error?: boolean;
  className?: string;
}

export const FloatTextInput = React.forwardRef<HTMLInputElement, FloatTextInputProps>(
  ({ label, name, className, ...rest }, ref) => {
    const classes = cn("FloatTextInput", className);

    return (
      <div className={classes}>
        <TextInput variant="standard" className="FloatTextInput__input" name={name} id={name} {...rest} ref={ref} />
        <label className="FloatTextInput__label" htmlFor={name}>
          {label}
        </label>
      </div>
    );
  }
);
