import * as React from "react";
import cn from "classnames";

import "./styles.css";

export interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  className?: string;
}

export const RadioButton: React.FC<RadioButtonProps> = ({ label, className, ...rest }) => {
  const classes = cn("RadioButton", className);

  return (
    <label className={classes}>
      <input type="radio" className="RadioButton__input" {...rest} />
      <span className="RadioButton__radio" />
      {label}
    </label>
  );
};
