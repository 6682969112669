import * as React from "react";
import { FormikErrors, FormikTouched, FieldArray, ErrorMessage } from "formik";
import { Skill } from "app/types";
import FormControl from "../../../../components/FormControl";
import AsyncReactSelect from "../../../../components/AsyncReactSelect";
import { ProfessionalExperienceValues } from "../../types";
import WorkExperienceField from "./WorkExperienceField";

import "./styles.css";
import { filterSkills } from "../../utils";

interface ProfessionalExperienceProps {
  values: ProfessionalExperienceValues;
  errors: FormikErrors<ProfessionalExperienceValues>;
  touched: FormikTouched<ProfessionalExperienceValues>;
  skills: Skill[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
}

export const ProfessionalExperience: React.FC<ProfessionalExperienceProps> = ({
  values,
  errors,
  touched,
  skills,
  setFieldValue,
  setFieldTouched,
}) => {
  return (
    <>
      <FormControl label="What technical skills do you really have? " error={touched.skills && !!errors.skills}>
        <AsyncReactSelect
          name="skills"
          placeholder="We know that you will quickly pick up new technologies 
          but help us understand what you are good at"
          value={values.skills}
          isMulti={true}
          error={touched.skills && !!errors.skills}
          loadOptions={(search: string, prevOptions: any) => filterSkills(skills, search, prevOptions)}
          onBlur={() => setFieldTouched("skills")}
          onChange={(value: any) => setFieldValue("skills", value)}
        />
        <ErrorMessage name="skills" render={(msg) => <div className="FormControl__label error-message">Required</div>} />
      </FormControl>
      <div className="ProfessionalExperience__work">
        <p className="ProfessionalExperience__work-title">What have you been doing? </p>
        <p className="ProfessionalExperience__work-description">
          What have you been doing? Although we pay more attention to your talents and not your professional journey -
          still we need some details
        </p>

        <FieldArray
          name="workExperience"
          render={(fieldArrayProps) => (
            <WorkExperienceField workExperience={values.workExperience} {...fieldArrayProps} />
          )}
        />
      </div>
    </>
  );
};
