import * as React from "react";
import { Props, Styles } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";

import "./styles.css";

function getStyles(error?: boolean, isMulti?: boolean) {
  const styles: Styles = {
    control: (base, { isFocused }) => ({
      ...base,
      minHeight: 48,
      borderRadius: 10,
      boxShadow: "none",
      "&:hover": { borderColor: isFocused ? "#766ed7" : error ? "#fc5a5a" : "#a09ae0" },
      borderColor: isFocused ? "#766ed7" : error ? "#fc5a5a" : "#e2e2ea",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingRight: "15px",
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "10px 15px",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "14px",
      lineHeight: "14px",
      letterSpacing: 0.1,
    }),
    singleValue: (base) => ({
      ...base,
      color: "#44444f",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#b5b5be",
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? "#766ed7" : "#fff",

      "&:active": {
        backgroundColor: "#a09ae0",
        color: "#fff",
      },

      "&:hover": {
        backgroundColor: isSelected ? "#766ed7" : "#a09ae0",
        color: "#fff",
      },
    }),
  };

  return styles;
}

export interface ReactSelectProps extends Props {
  error?: boolean;
}

export const AsyncReactSelect: React.FC<ReactSelectProps> = ({ error, isMulti, loadOptions, ...rest }) => {
  const styles = getStyles(error, isMulti);
  return (
    <AsyncPaginate
      styles={styles}
      components={{
        IndicatorSeparator: () => null,
      }}
      isMulti={isMulti}
      loadOptions={loadOptions}
      {...rest}
    />
  );
};
