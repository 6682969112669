import * as React from "react";
import cn from "classnames";

import "./styles.css";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  variant?: "outlined" | "contained";
}

export const Button: React.FC<ButtonProps> = ({ variant = "contained", children, className, ...rest }) => {
  const classes = cn("Button", `Button--${variant}`, className);

  return (
    <button className={classes} {...rest}>
      {children}
    </button>
  );
};
