import React from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import FloatTextInput from "../FloatTextInput";
import TextInput from "../TextInput";

import "react-datepicker/dist/react-datepicker.css";

export interface DatePickerProps extends ReactDatePickerProps {
  label: string;
  name: string;
  placeholder: string;
  floatingTextInput?: boolean;
  error?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  label,
  name,
  placeholder,
  error,
  floatingTextInput = true,
  ...rest
}) => {
  return (
    <ReactDatePicker
      {...rest}
      name={name}
      id={name}
      placeholderText={placeholder}
      customInput={
        floatingTextInput ? (
          <FloatTextInput label={label} name={name} error={error} />
        ) : (
          <TextInput variant="standard" name={name} error={error} />
        )
      }
    />
  );
};
