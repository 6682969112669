import { FormikErrors, FormikTouched, Field, ErrorMessage } from "formik";
import * as React from "react";
import { GeneralInformationValues } from "../../types";
import { formatDate } from "../../utils";
import FormControl from "../../../../components/FormControl";
import TextInput from "../../../../components/TextInput";
import RadioButton from "../../../../components/RadioButton";
import DatePicker from "../../../../components/DatePicker";

import "./styles.css";

interface GeneralInformationProps {
  values: GeneralInformationValues;
  errors: FormikErrors<GeneralInformationValues>;
  touched: FormikTouched<GeneralInformationValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
}

export const GeneralInformation: React.FC<GeneralInformationProps> = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleBlur,
}) => {
  return (
    <React.Fragment>
      <FormControl label="What's your hourly rate?" error={touched.hourlyRate && !!errors.hourlyRate}>
        <Field
          name="hourlyRate"
          placeholder="In USD please, we don't want to fall short with all the conversion rates"
          type="number"
          error={touched.hourlyRate && !!errors.hourlyRate}
          as={TextInput}
        />
        <ErrorMessage name="hourlyRate" render={(msg) => <div className="FormControl__label error-message">{msg}</div>} />
      </FormControl>
      <FormControl label="What's your preference?" className="GeneralInformation__field">
        <div className="GeneralInformation__radio-group">
          <Field
            name="workType"
            type="radio"
            value="remote"
            as={RadioButton}
            label="Working remotely"
            className="GeneralInformation__radio"
          />
          <Field
            name="workType"
            type="radio"
            value="relocate"
            as={RadioButton}
            label="Open to relocate/work onsite"
            className="GeneralInformation__radio"
          />
          <Field
            name="workType"
            type="radio"
            value="rotation"
            as={RadioButton}
            label="Open to rotation work"
            className="GeneralInformation__radio"
          />
        </div>
      </FormControl>

      <FormControl label="How much time you have for us?" className="GeneralInformation__field">
        <div className="GeneralInformation__radio-group">
          <Field
            name="employmentType"
            type="radio"
            value="fullTime"
            as={RadioButton}
            label="Full time"
            className="GeneralInformation__radio"
          />
          <Field
            name="employmentType"
            type="radio"
            value="partTime"
            as={RadioButton}
            label="Part time"
            className="GeneralInformation__radio"
          />
        </div>
        {values.employmentType === "partTime" && (
          <Field
            name="employmentTypeHours"
            variant="outlined"
            placeholder="How many hours weekly do you have for us?"
            type="number"
            className="GeneralInformation__employment-hours-field"
            error={touched.employmentTypeHours && !!errors.employmentTypeHours}
            as={TextInput}
          />
        )}
      </FormControl>

      <FormControl
        label="When are you available to join us?"
        className="GeneralInformation__field"
        error={touched.availabilityDate && !!errors.availabilityDate}
      >
        <DatePicker
          name="availabilityDate"
          label="mm/dd/yyyy"
          placeholder="mm/dd/yyyy"
          floatingTextInput={false}
          error={touched.availabilityDate && !!errors.availabilityDate}
          selected={values.availabilityDate !== "" ? new Date(values.availabilityDate) : undefined}
          onChange={(date) => {
            if (!date) {
              return;
            }
            setFieldValue("availabilityDate", formatDate(date as Date));
          }}
          onBlur={handleBlur}
        />
        <ErrorMessage name="availabilityDate" render={(msg) => <div className="FormControl__label error-message">{msg}</div>} />
      </FormControl>
    </React.Fragment>
  );
};
