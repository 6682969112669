import * as React from "react";
import cn from "classnames";

import "./styles.css";

export interface SelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  label: string;
  name: string;
  value: string | number | readonly string[];
  children: React.ReactNode;
  className?: string;
}

export const Select: React.FC<SelectProps> = ({ label, name, className, children, value, ...rest }) => {
  const classes = cn("Select", className);

  return (
    <div className={classes}>
      <select className="Select__select" name={name} id={name} value={value} {...rest}>
        {children}
      </select>
      <label
        className={cn("Select__label", {
          "Select__label--inactive": value !== "",
          "Select__label--active": value === "",
        })}
        htmlFor={name}
      >
        {label}
      </label>
    </div>
  );
};
