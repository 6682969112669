import * as React from "react";
import { FieldArrayRenderProps } from "formik";
import { EducationItem } from "../../../types";
import Button from "../../../../../components/Button";
import { formatDate } from "../../../utils";
import EducationInfoCard from "../EducationInfoCard";
import EducationInfoSubForm from "./EducationInfoSubForm";

import "./styles.css";

interface EducationFieldProps extends FieldArrayRenderProps {
  education: EducationItem[];
}

function getEducationInfoInitialValues() {
  return {
    title: "",
    school: "",
    degree: "",
    fieldOfStudy: "",
    from: formatDate(new Date()),
    to: formatDate(new Date()),
    completed: false,
  };
}

export const EducationField: React.FC<EducationFieldProps> = ({ education, form, push, remove }) => {
  const [showEducationSubForm, setShowEducationSubForm] = React.useState(false);

  const handleShowSubForm = React.useCallback(() => {
    setShowEducationSubForm(true);
    push(getEducationInfoInitialValues());
  }, [push]);

  const handleShowEditSubForm = React.useCallback((educationItem: EducationItem) => {
    setShowEducationSubForm(true);
    educationItem.edit = true;
  }, []);

  return (
    <React.Fragment>
      {education.map((educationItem, index) => {
        if ((!educationItem.completed || educationItem.edit) && showEducationSubForm) {
          return (
            <EducationInfoSubForm
              key={index}
              educationInfo={educationItem}
              educationInfoIndex={index}
              errors={form.errors}
              touched={form.touched}
              onSetShowSubForm={setShowEducationSubForm}
              onRemoveFieldValue={remove}
              onSetFieldValue={form.setFieldValue}
              onBlur={form.handleBlur}
            />
          );
        }

        return (
          <EducationInfoCard
            key={index}
            title={educationItem.title}
            school={educationItem.school}
            degree={educationItem.degree}
            fieldOfStudy={educationItem.fieldOfStudy}
            to={educationItem.to}
            from={educationItem.from}
            editForm={() => handleShowEditSubForm(educationItem)}
            className="Education__InfoCard"
          />
        );
      })}
      {!showEducationSubForm && (
        <Button type="button" variant="outlined" onClick={handleShowSubForm}>
          + Add
        </Button>
      )}
    </React.Fragment>
  );
};
