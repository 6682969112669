import * as React from "react";
import cn from "classnames";

import "./styles.css";

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
}

export const TextArea: React.FC<TextAreaProps> = ({ className, error, ...rest }) => {
  const classes = cn("TextArea", { "TextArea--error": error }, className);

  return <textarea {...rest} className={classes} />;
};
