import * as React from "react";
import { FormikErrors, FormikTouched, FieldArray, Field, ErrorMessage } from "formik";
import { EducationValues } from "../../types";
import TextInput from "../../../../components/TextInput";
import CheckBox from "../../../../components/CheckBox";
import EducationField from "./EducationField";
import CertificatesField from "./CertificatesField";

import "./styles.css";

interface EducationProps {
  values: EducationValues;
  touched: FormikTouched<EducationValues>;
  errors: FormikErrors<EducationValues>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
}

export const Education: React.FC<EducationProps> = ({ touched, values, errors, handleBlur, handleChange }) => {
  return (
    <div className="Education">
      <p className="Education__title">What did you study?</p>
      <p className="Education__description">
        Unless you follow Steve Jobs or Richard Branson's philosophy and decided it is not for you
      </p>

      <FieldArray
        name="education"
        render={(fieldArrayProps) => <EducationField education={values.education} {...fieldArrayProps} />}
      />

      <p className="Education__title Education__certificates-title">Any Certificates or Trainings?</p>
      <FieldArray
        name="certificates"
        render={(fieldArrayProps) => <CertificatesField certificates={values.certificates} {...fieldArrayProps} />}
      />

      <p className="Education__title Education__language-title">What languages do you speak?</p>
      <p className="Education__description">
        We need to be able to properly communicate with you so if you speak English we are good
      </p>
      <div className="Education__language-options">
        <ErrorMessage name="english" render={(msg) => <div className="FormControl__label error-message">Required</div>} />
        <Field name="english" type="checkbox" as={CheckBox} children={"English"} />
        <Field
          name="other"
          type="checkbox"
          as={CheckBox}
          children={
            <TextInput
              name="otherLanguages"
              variant="standard"
              placeholder="Other"
              error={touched.otherLanguages && !!errors.otherLanguages}
              value={values.otherLanguages}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          className="Education__language-option"
        />
      </div>
    </div>
  );
}
