import * as Yup from "yup";
// import isMobilePhone from "validator/lib/isMobilePhone";
import { Step } from "./types";

export function useValidationSchema(step: Step) {
  const personalInfoSchema = Yup.object().shape({
    fullName: Yup.string()
      .matches(/^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/)
      .required(),
    title: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.string()
      .matches(/^\+(?:[0-9]●?){6,14}[0-9]$/)
      // .test("isMobilePhone", "phone is not valid", (value) => isMobilePhone(value || ""))
      .required(),
    nationality: Yup.string().required(),
    residence: Yup.string().required(),
    pitch: Yup.string().required(),
  });

  const professionalExperienceSchema = Yup.object().shape({
    skills: Yup.string().required(),
    workExperience: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required(),
        company: Yup.string().required(),
        description: Yup.string().required(),
        from: Yup.string().required(),
        to: Yup.string().required()
      })
    ),
  });

  const educationSchema = Yup.object().shape({
    education: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required(),
        school: Yup.string().required(),
        degree: Yup.string().required(),
        fieldOfStudy: Yup.string().required(),
        from: Yup.string().required(),
        to: Yup.string().required()
      })
    ),
    certificates: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required(),
        school: Yup.string().required(),
        name: Yup.string().required(),
      })
    ),
    other: Yup.boolean().required(),
    otherLanguages: Yup.string().when("other", {
      is: true,
      then: Yup.string().required(),
    }),
  });

  const generalInformationSchema = Yup.object().shape({
    hourlyRate: Yup.string()
      .required()
      .test('hasDecimals', 'Hourly rate must have no decimals', (value) => {
        if (value) {
          const decimal = value.split('.')[1];
          if (!decimal) {
            return true;
          }
        }
        
        return false;
      })
      .test('isMax3Digits', 'Hourly rate must be less than 1000 USD', (value) => {
        if (Number(value) < 1000) {
          return true;
        }
        
        return false;
      })
      .test("isPositive", "Hourly rate should be positive", (value) => {
        if (Number(value) > 0) {
          return true;
        }

        return false;
      }),
    availabilityDate: Yup.string().required()
    .test("isInFuture", "Availability date can't be a past date", (value) => {
      if (value) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        if (Date.parse(value) >= Date.parse(today.toString())) {
          return true;
        }
      }

      return false;
    }),
    employmentType: Yup.string().required(),
    employmentTypeHours: Yup.string().when("employmentType", {
      is: "partTime",
      then: Yup.string()
        .required()
        .max(2)
        .test("isPositive", "employmentTypeHours should be positive", (value) => {
          if (Number(value) > 0) {
            return true;
          }

          return false;
        }),
    }),
  });

  switch (step) {
    case Step.PersonalInfo:
      return personalInfoSchema;
    case Step.ProfessionalExperience:
      return professionalExperienceSchema;
    case Step.Education:
      return educationSchema;
    case Step.GeneralInformation:
      return generalInformationSchema;
  }
}
