import * as React from "react";

import "./styles.css";

export interface ProgressBarProps {
  progress: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  const filledStripStyles = {
    width: `${progress}%`,
  };

  return (
    <div className="ProgressBar">
      <span style={filledStripStyles} />
    </div>
  );
};
